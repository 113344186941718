const StepsExperience = () =>{
    return(
        <div className="container-fluid ptb--100">
            <div className="container text-center">
                <h3 className="text-primary">Passo a passo para seu sucesso médico internacional</h3>
                <p className="fw-bold text-black">Nosso programa "Experience Hunting" é projetado para tornar sua jornada médica internacional simples e eficaz. Aqui está uma visão geral das etapas que o levarão ao seu destino!</p>
                <div className="row text-center ptb--50">
                    <h5 className="text-primary mb-5">Veja como será a sua trilha do Sucesso:</h5>
                    <div className="col-12 col-lg-4 text-center px-5">
                        <h3 className="text-primary mb-0" style={{fontSize:'62px'}}>1º</h3>
                        <p className="text-primary fw-bold lh-sm">Encontro</p>
                        <p className="fs-4 fw-normal text-black" style={{textAlign:'justify'}}>Vamos iniciar o Programa com um primeiro encontro analisando seu perfil e determinando o tipo de intercâmbio e destino mais adequados para você. Durante essa conversa inicial, vamos explorar seus objetivos, entender seu perfil e discutir suas aspirações futuras. Esse é o ponto de partida para personalizarmos sua jornada internacional.</p>
                    </div>
                    <div className="col-12 col-lg-4 text-center px-5">
                        <h3 className="text-primary mb-0" style={{fontSize:'62px'}}>2º</h3>
                        <p className="text-primary fw-bold lh-sm">Encontro</p>
                        <p className="fs-4 fw-normal text-black" style={{textAlign:'justify'}}>Vamos revisar e ajustar seu currículo para se adequar perfeitamente às principais oportunidades internacionais. Apresentaremos opções iniciais de vagas com base em suas preferências e perfil, seguidas por sessões de feedback e edição do currículo.</p>
                    </div>
                    <div className="col-12 col-lg-4 text-center px-5">
                        <h3 className="text-primary mb-0" style={{fontSize:'62px'}}>3º</h3>
                        <p className="text-primary fw-bold lh-sm">Encontro</p>
                        <p className="fs-4 fw-normal text-black" style={{textAlign:'justify'}}>Este é o momento de compreender os próximos passos, revisar os documentos solicitados pelas Universidades conforme seus padrões específicos e identificar quais são necessários para enviar à Instituição.</p>
                    </div>
                    <div className="col-12 col-lg-6 text-center px-5">
                        <h3 className="text-primary mb-0" style={{fontSize:'62px'}}>4º</h3>
                        <p className="text-primary fw-bold lh-sm">Encontro</p>
                        <p className="fs-4 fw-normal text-black" style={{textAlign:'justify'}}>Nesta etapa, vamos te ajudar a redigir os e-mails e a carta de recomendação destinados aos professores responsáveis pelas Instituições de escolha.</p>
                    </div>
                    <div className="col-12 col-lg-6 text-center px-5">
                        <h3 className="text-primary mb-0" style={{fontSize:'62px'}}>5º</h3>
                        <p className="text-primary fw-bold lh-sm">Encontro</p>
                        <p className="fs-4 fw-normal text-black" style={{textAlign:'justify'}}>Este é o momento para esclarecer quaisquer dúvidas remanescentes, receber orientações adicionais sobre financiamentos e bolsas, e revisar seu currículo e e- mail padrão para inscrições.</p>
                    </div>
                </div>
                <div className="row bg-primary rounded-3 p-3">
                    <span className="fs-4 text-white">Ao término dos 5 encontros, serão fornecidos contatos de professores parceiros da Academy Abroad, conforme a necessidade de cada participante do programa Experience Hunting.</span>
                </div>
            </div>
        </div>
    )
}

export default StepsExperience;