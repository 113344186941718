import img1 from '../../assets/images/kenji.jpg'
import { FaArrowRight, FaCheck } from "react-icons/fa";

const AboutExperience = () => {
    return(
        <div className="container-fluid ptb--100">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <div className='experience-image-box mb-5 mb-lg-0'>
                        {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/5x2jTLdNXCI?si=bTBSwkpcyEVQkwqU&amp;controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
                        <img src={img1} alt='foto descritiva' />
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <h5 className='text-primary'>
                            Bem-vindo ao programa "Experience Hunting" da Academy Abroad, sua porta de entrada para uma carreira internacional
                        </h5>
                        <p className='fw-bold'>Para quem é o "Experience Hunting"?</p>
                        <ul>
                            <li className='row'>
                                <div className='col-1'><FaCheck className='text-primary' /></div> 
                                <div className='col'>Para profissionais que buscam oportunidades internacionais de ensino, como intercâmbios em Universidades do exterior;</div>
                            </li>
                            <li className='row'>
                                <div className='col-1'><FaCheck className='text-primary' /></div> 
                                <div className='col'>Médicos que buscam estágio durante o período da residência nos principais hospitais internacionais;</div>
                            </li>
                            <li className='row'>
                                <div className='col-1'><FaCheck className='text-primary' /></div> 
                                <div className='col'>Estudantes de medicina que buscam fazer intercâmbio em hospitais e universidades internacionais, como Harvard, Yale, NYU, Oxford e Stanford;</div>
                            </li>
                            <li className='row'>
                                <div className='col-1'><FaCheck className='text-primary' /></div> 
                                <div className='col'>Pesquisadores que têm interesse em expandir sua atuação, buscando oportunidade de estudos em centros de pesquisa internacionais;</div>
                            </li>
                        </ul>

                        <a href='https://wkf.ms/3PwhOCn' className='edu-btn'>Inscrever agora <FaArrowRight /></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutExperience;