import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import HeaderTwo from "../../common/header/HeaderTwo";
import ImersaoHeroSection from "../bhi/components/ImersaoHeroSection";
import bhiVideo from '../../assets/images/bostonHeader.mp4'
import ImersaoCtaSection from "../bhi/components/ImersaoCtaSection";
import ImersaoHowItWorks from "../bhi/components/ImersaoHowItWorks";
import BhiSpeakers from "../bhi/components/BhiSpeakers";
import BhiFounders from "../bhi/components/BhiFounders";
import ImersaoAbout from "../bhi/components/ImersaoAbout";
import FooterOne from "../../common/footer/FooterOne";
import ImersaoInvest from "../bhi/components/ImersaoInvest";
import BhiHeader from "../bhi/components/BhiHeader";
import img1 from "../../assets/images/bhi-slide/1.png";
import img2 from "../../assets/images/bhi-slide/2.png";
import img3 from "../../assets/images/bhi-slide/3.png";
import img4 from "../../assets/images/bhi-slide/4.png";
import img5 from "../../assets/images/bhi-slide/5.png";
import img6 from "../../assets/images/bhi-slide/6.png";
import img7 from "../../assets/images/bhi-slide/7.png";
import img8 from "../../assets/images/bhi-slide/8.png";
import img9 from "../../assets/images/bhi-slide/9.png";
import img10 from "../../assets/images/bhi-slide/10.png";
import img11 from "../../assets/images/bhi-slide/11.png";
import img12 from "../../assets/images/bhi-slide/12.png";
import img13 from "../../assets/images/bhi-slide/13.png";
import img14 from "../../assets/images/bhi-slide/14.png";
import img15 from "../../assets/images/bhi-slide/15.jpg";
import img16 from "../../assets/images/bhi-slide/16.jpg";
import img17 from "../../assets/images/bhi-slide/17.jpg";
import img18 from "../../assets/images/bhi-slide/18.jpg";
import img19 from "../../assets/images/bhi-slide/19.jpg";
import { Helmet } from "react-helmet";
import QuestionsExperience from "../../components/questions/QuestinsExperience";

export default function Imersoes(){
    const { slug } = useParams()
    const API_URL = 'https://api.academyabroad.com.br/api/site/'
    const [data, setData] = useState()

    const slide1 = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10]
    const slide2 = [img11, img12, img13, img14, img15, img16, img17, img18, img19]


    useEffect(() => {
        axios.get(`${API_URL}imersoes/${slug}`).then(
            response => {
            console.log(response)
            setData(response.data)
            }
        ).catch((error) => console.log(error))
    },[])

    const faqData = [
        {
            "pergunta": "Como funciona o programa?",
            "resposta": "O Boston Healthcare Innovation Program (BHI) é um programa híbrido, com 2 meses online contendo videoaulas de especialistas e materiais escritos sobre gestão, empreendedorismo e inovação em saúde. Após essa etapa, o programa presencial ocorre entre 23 e 27 de junho de 2025 em Boston, EUA, incluindo visitas às Universidades de Harvard, MIT e Babson College, com mais de 60h de conteúdo online básico e avançado."
        },
        {
            "pergunta": "Como acontecerá o programa presencial?",
            "resposta": "A etapa presencial, chamada Boston Healthcare Innovation Immersion, será realizada de 23 a 27 de junho de 2025 em Boston. Inclui palestras de professores de Harvard e MIT, workshops práticos, conversas com fundadores de startups, visitas técnicas e atividades opcionais à noite, como jantares e networking."
        },
        {
            "pergunta": "O que está incluído no programa?",
            "resposta": "Inclui o programa online de 2 meses, a imersão presencial em Boston, café da manhã e almoço nos dias presenciais, um welcome kit personalizado e acesso a uma comunidade life long learning. O investimento é de R$ 26.892,00, parceláveis em 12x de R$ 2.241,00 no cartão."
        },
        {
            "pergunta": "Quais as formas de pagamento?",
            "resposta": "O valor pode ser pago à vista via boleto ou PIX, ou parcelado em até 12x de R$ 2.241,00 no cartão de crédito, sem juros."
        },
        {
            "pergunta": "Alimentação está incluída no programa?",
            "resposta": "Café da manhã e almoço estão incluídos nos dias presenciais. Jantar, café da tarde e outros lanches são de responsabilidade do participante."
        },
        {
            "pergunta": "O BHI é totalmente em inglês? Se meu inglês não for fluente, posso participar?",
            "resposta": "A parte online é em português, e a presencial inclui atividades em inglês, com algumas palestras ministradas por professores brasileiros. O inglês utilizado é de fácil compreensão, adequado para estrangeiros."
        },
        {
            "pergunta": "Preciso ser médico(a) para participar?",
            "resposta": "Não, o programa é aberto a profissionais de saúde, executivos, empreendedores e interessados em gestão, empreendedorismo e inovação em saúde, independente da experiência prévia."
        },
        {
            "pergunta": "Existe desconto em algum hotel?",
            "resposta": "Sim, há parcerias com hotéis como The Inn at Longwood Medical Area e Hilton Garden Inn, com diárias entre US$ 350 e US$ 450, que podem ser divididas entre até 4 pessoas. A contratação é responsabilidade do participante."
        },
        {
            "pergunta": "Preciso ter experiência prévia em gestão?",
            "resposta": "Não, o programa oferece base e aprofundamento em gestão, empreendedorismo e inovação, para nivelar o conhecimento entre os participantes, independente da formação ou experiência."
        },
        {
            "pergunta": "O programa emite certificado? Quem emite o certificado?",
            "resposta": "Sim, o programa emite dois certificados de 60h: um do Boston Healthcare Innovation Executive Program (online) e outro do Boston Healthcare Innovation Immersion (presencial), ambos pela Academy Abroad."
        },
        {
            "pergunta": "O que eu preciso ter para ir ao BHI?",
            "resposta": "É necessário um visto americano válido e seguro-viagem. A apólice do seguro deve ser apresentada antes da viagem, sendo de responsabilidade do participante."
        },
        {
            "pergunta": "Ainda não tenho visto, como posso proceder?",
            "resposta": "A Academy Abroad possui parcerias com despachantes que auxiliam na obtenção de visto e passaporte. Após a inscrição, os coordenadores fornecem contatos de parceiros."
        },
        {
            "pergunta": "Orientações importantes:",
            "resposta": "É recomendado chegar em Boston com 2-3 dias de antecedência para evitar problemas com atrasos ou bagagens. No domingo antes do BHI, há um jantar opcional com professores do MIT e participantes para networking."
        }
    ]
    

    return(
        <>
            <Helmet>
                <head>
                        {`                    
                    <meta charSet="utf-8" />
                    <title>Boston Healthcare Innovation - BHI || Academy Abroad</title>

                    {/* Meta tags padrão */}
                    <meta name="robots" content="noindex, follow" />
                    <meta name="description" content="Programa internacional desenhado para médicos, executivos em saúde, estudantes de medicina e gestores, dentro de duas das melhores Universidades do mundo." />
                    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

                    {/* Open Graph (para redes sociais) */}
                    <meta property="og:title" content="Boston Healthcare Innovation - BHI" />
                    <meta property="og:description" content="Programa internacional desenhado para médicos, executivos em saúde, estudantes de medicina e gestores, dentro de duas das melhores Universidades do mundo." />
                    <meta property="og:image" content="https://academyabroad.com.br/static/media/1.67e9913cc95c5c837c2b.png" />
                    <meta property="og:image:type" content="image/png" />
                    <meta property="og:type" content="website" />

                    {/* Twitter Cards */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content={data ? data.titulo : "Boston Healthcare Innovation - BHI"} />
                    <meta name="twitter:description" content={data ? data.descricao : "Programa internacional desenhado para médicos, executivos em saúde, estudantes de medicina e gestores, dentro de duas das melhores Universidades do mundo."} />
                    <meta name="twitter:image" content="https://academyabroad.com.br/static/media/1.67e9913cc95c5c837c2b.png" />
                    `}
                </head>
            </Helmet>
            {data && (
            <>
                <HeaderTwo styles=" header-style-2" />
                <BhiHeader logo={data.upload_logo}/>
                <ImersaoHeroSection 
                    titulo={data.titulo}
                    descritivo={data.descricao}
                    card1={`Início do programa: ${data.data_programa}`}
                    card2={`Imersão presencial em Boston: ${data.data_presencial}`}
                    video={bhiVideo}
                />
                <ImersaoCtaSection 
                    titulo={data.cta_titulo}
                    descritivo={data.cta_descricao}
                    button={"Agendar reunião"}
                    action={data.cta_link}
                    slide={slide1}
                />
                <ImersaoHowItWorks
                    data={data} 
                />
                <BhiSpeakers
                descritivo={`<p class="mb-0 lh-1">Empreendedores que vão te mostrar o que já estão fazendo para revolucionar a área da saúde.</p>
                    <p "> Founders que participaram de edições anteriores do ${data.titulo}</p>`} />
                <BhiFounders 
                    descritivo={`<p class="mb-0 lh-1 text-white">Empreendedores que vão te mostrar o que já estão fazendo para revolucionar a área da saúde.</p>
                    <p class="text-white"> Founders que participaram de edições anteriores do ${data.titulo}</p>`}
                />
                <ImersaoAbout
                data={data}
                slide={slide2} 
                />
                <ImersaoInvest 
                title={data.investimento_titulo}
                action={`/imersoes-faq/${data.slug}`}
                data={data.investimento_vantagens}
                valor={data.valor}
                />
                <QuestionsExperience questions={faqData} program={data.titulo}/>
                <FooterOne />
            </>
            )}
        </>
    )
}