import SEO from "../../common/SEO";
import { useParams } from 'react-router-dom';
import HeaderTwo from "../../common/header/HeaderTwo";
import GalleryFaq from "./components/Gallery";
import FooterOne from "../../common/footer/FooterOne";
import QuestionsExperience from "../../components/questions/QuestinsExperience";
import BuyIn from "./components/BuyIn";
import { useEffect, useState } from "react";
import axios from "axios";
import img1 from "../../assets/images/bhi-slide/1.png";
import img2 from "../../assets/images/bhi-slide/2.png";
import img3 from "../../assets/images/bhi-slide/3.png";
import img4 from "../../assets/images/bhi-slide/4.png";
import img5 from "../../assets/images/bhi-slide/5.png";
import img6 from "../../assets/images/bhi-slide/6.png";
import img7 from "../../assets/images/bhi-slide/7.png";
import img8 from "../../assets/images/bhi-slide/8.png";
import img9 from "../../assets/images/bhi-slide/9.png";
import img10 from "../../assets/images/bhi-slide/10.png";
import img11 from "../../assets/images/bhi-slide/11.png";
import img12 from "../../assets/images/bhi-slide/12.png";
import img13 from "../../assets/images/bhi-slide/13.png";
import img14 from "../../assets/images/bhi-slide/14.png";
import img15 from "../../assets/images/bhi-slide/15.jpg";
import img16 from "../../assets/images/bhi-slide/16.jpg";
import img17 from "../../assets/images/bhi-slide/17.jpg";
import img18 from "../../assets/images/bhi-slide/18.jpg";
import img19 from "../../assets/images/bhi-slide/19.jpg";

// const questionsBhi = [
//     {
//         question:'Como funciona o programa?',
//         answer: 'O Boston Healthcare Innovation (BHI) é um programa híbrido, com 2 meses online entre abril e maio de 2024, com módulos com videoaulas, materiais escritos e materiais complementares sobre gestão, inovação e empreendedorismo em saúde. O conteúdo online serve para colocar em mesmo nível de conhecimento todos os participantes, independentes de sua experiência e formação profissional, com mais de 60h de conteúdo básico e avançado, com grandes referências no setor de saúde. Após os dois meses online, o programa acontece de forma presencial, entre os dias 3 a 7 de junho de 2024. A parte presencial do programa acontece na cidade de Boston, em Massachusetts, nos Estados Unidos, com visitas às Universidades de Harvard e MIT.'
//     },
//     {
//         question:'Como acontecerá o programa presencial?',
//         answer: 'O programa presencial, chamado de Boston Healthcare Immersion, acontecerá entre os dias 3 e 7 de junho de 2024, na cidade de Boston, dividido em etapas. A parte da manhã se inicia com o café da manhã e uma palestra dada por um profissional de Harvard ou MIT, seguido por um workshop prático, em grupo, entre os participantes, e depois o que chamamos de "Conversa com o CEO/VC" quando um fundador de uma startup americana ou um fundo de investimento contam suas experiências práticas aos participantes. Após o almoço, o grupo segue para as visitas guiadas por Harvard e MIT. Durante a parte da noite, os participantes ficam livres para fazer networking, e participar de jantares, boliche e bares de Boston, opcionais. A parte da noite é de custo e responsabilidade do participante.'
//     },
//     {
//         question:'O que está incluído no programa?',
//         answer: 'Está incluído no BHI o programa online, a imersão presencial em Boston e a comunidade life long learning após a imersão, com café da manhã e almoço incluídos em todos os dias do programa presencial. Além do programa completo, os participantes recebem um welcome kit. Tudo incluído no programa tem um valor de investimento de R$19.800,00 que podem ser parcelados em 12 x R$1.650,00 no cartão de crédito.'
//     },
//     {
//         question:'Quais são as formas de pagamento?',
//         answer: 'R$19.800,00 à vista no boleto ou PIX, ou então parcelamento em até 12x sem juros. O parcelamento de 12x é de parcelas iguais de R$1.650,00 feitos no cartão de crédito em link de pagamento fornecido pelos organizadores do programa ou dentro da própria plataforma da Academy Abroad.'
//     },
//     {
//         question:'Alimentação está incluída no programa?',
//         answer: 'Café da manhã e almoço estão incluídos em todos os dias presenciais do programa, entre os dias 3 e 7 de junho de 2024. Jantar, café da tarde e demais lanches não estão inclusos, sendo de responsabilidade de cada participante.'
//     },
//     {
//         question:'O BHI é totalmente em inglês? Se meu inglês não for fluente, posso participar?',
//         answer: 'Apenas parte do BHI é em inglês. A parte online do programa é totalmente em português, dada por referências do mercado de gestão, empreendedorismo e inovação em saúde. Durante a parte presencial em Boston, as visitas guiadas por professores de Harvard e MIT são em português, feitas por professores, pesquisadores, médicos e executivos brasileiros que trabalham nas instituições. As palestras e workshops são em sua maioria inglês. Porém, o inglês dos participantes é de fácil compreensão, por se tratar de uma cidade cosmopolita, com alto número de estrangeiros, dentre eles brasileiros e de demais países latinoamericanos.'
//     },
//     {
//         question:'Preciso ser médico(a) para participar?',
//         answer: 'Não é necessário ser médico ou médica para participar do programa. O BHI é destinado a qualquer profissional de saúde, executivo, empreendedor ou interessado em gestão, empreendedorismo e inovação em saúde. O conteúdo online serve para colocar em mesmo nível de conhecimento todos os participantes, independentes de sua experiência e formação profissional, com mais de 60h de conteúdo básico e avançado, com grandes referências no setor de saúde.'
//     },
//     {
//         question:'Existe desconto em algum hotel?',
//         answer: 'O BHI possui parceria institucional com alguns hotéis na cidade de Boston, que com desconto possuem quartos com valor de US$350 a US$450 a diária, a depender da disponibilidade do hotel e temporada. Os quartos podem ser divididos por até 4 pessoas, com o valor da diária também dividido de acordo com o número de hóspedes em cada quarto. Os hotéis parceiros são o The Inn at Longwood Medical Area, e o Hilton Garden Inn. A contratação dos hotéis é de responsabilidade do participante e pode ser orientada pela organização do BHI.'
//     },
//     {
//         question:'Preciso ter experiência prévia em gestão?',
//         answer: 'Não é preciso ter experiência prévia ou conhecimento vasto em gestão. O programa online é criado para dar base e aprofundar conhecimentos sobre gestão, empreendedorismo e inovação em saúde, para que os participantes, independente da formação profissional e experiência, tenham todos uma vasta experiência.'
//     },
//     {
//         question:'O programa emite certificado? Quem emite o certificado?',
//         answer: 'O programa emite dois certificados de 60h, sendo um certificado do Boston Healthcare Innovation Executive Program, referente à parte online do programa, e um certificado do Boston Healthcare Innovation Immersion, ambos emitidos pela Academy Abroad Serviços de Educação LTDA. A Academy Abroad é uma edtech, de caráter privado, sem vínculo direto com o setor educacional de Harvard e MIT, apesar de todo o programa apresentar profissionais das instituições e visitar as localidades de ambas as Universidades. Portanto, o programa não emite certificado em nome de Harvard e MIT.'
//     }
// ]

// const questionsFcj = [
//     {
//         question:'Como funciona o programa?',
//         answer: 'O FCJ Investment Mission USA é um programa híbrido, com 2 meses online entre agosto e setembro de 2024, com módulos com videoaulas, materiais escritos e materiais complementares sobre gestão, inovação e empreendedorismo. O conteúdo online serve para colocar em mesmo nível de conhecimento todos os participantes, independentes de sua experiência e formação profissional, com mais de 60h de conteúdo básico e avançado, com grandes referências no setor de inovação e investimentos. Após os dois meses online, o programa acontece de forma presencial, entre os dias 21 a 25 de outubro de 2024. A parte presencial do programa acontece na cidade de Boston, em Massachusetts, nos Estados Unidos, com visitas às Universidades de Harvard e MIT.'
//     },
//     {
//         question:'Como acontecerá o programa presencial?',
//         answer: 'O programa presencial acontecerá entre os dias 21 e 25 de outubro de 2024, na cidade de Boston, dividido em etapas. A parte da manhã se inicia com o café da manhã e uma palestra dada por um profissional de Harvard ou MIT, seguido por um workshop prático, em grupo, entre os participantes, e depois o que chamamos de "Conversa com o CEO/VC" quando um fundador de uma startup americana ou um fundo de investimento contam suas experiências práticas aos participantes. Após o almoço, o grupo segue para as visitas guiadas por Harvard e MIT. Durante a parte da noite, os participantes ficam livres para fazer networking, e participar de jantares, boliche e bares de Boston, opcionais. A parte da noite é de custo e responsabilidade do participante.'
//     },
//     {
//         question:'O que está incluído no programa?',
//         answer: 'Está incluído na FCJ Investment Mission USA o programa online, a imersão presencial em Boston e a comunidade life long learning após a imersão, com café da manhã e almoço incluídos em todos os dias do programa presencial. Além do programa completo, os participantes recebem um welcome kit. Tudo incluído no programa tem um valor de investimento de R$19.800,00 que podem ser parcelados em 12 x R$1.650,00 no cartão de crédito.'
//     },
//     {
//         question:'Quais são as formas de pagamento?',
//         answer: 'R$19.800,00 à vista no boleto ou PIX, ou então parcelamento em até 12x sem juros. O parcelamento de 12x é de parcelas iguais de R$1.650,00 feitos no cartão de crédito em link de pagamento fornecido pelos organizadores do programa ou dentro da própria plataforma da Academy Abroad.'
//     },
//     {
//         question:'Alimentação está incluída no programa?',
//         answer: 'Café da manhã e almoço estão incluídos em todos os dias presenciais do programa, entre os dias 2 e 25 de outubro de 2024. Jantar, café da tarde e demais lanches não estão inclusos, sendo de responsabilidade de cada participante.'
//     },
//     {
//         question:'O FCJ Mission Usa by Academy Abroad é totalmente em inglês? Se meu inglês não for fluente, posso participar?',
//         answer: 'Apenas parte do programa é em inglês. A parte online do programa é totalmente em português, dada por referências do mercado de gestão, empreendedorismo e inovação em saúde. Durante a parte presencial em Boston, as visitas guiadas por professores de Harvard e MIT são em português, feitas por professores, pesquisadores e executivos brasileiros que trabalham nas instituições. As palestras e workshops são em sua maioria inglês. Porém, o inglês dos participantes é de fácil compreensão, por se tratar de uma cidade cosmopolita, com alto número de estrangeiros, dentre eles brasileiros e de demais países latinoamericanos.'
//     },
//     {
//         question:'Preciso fazer parte da FCJ para participar?',
//         answer: 'Não é necessário fazer parte da FCJ para participar. Contudo, as vagas iniciais serão destinadas aos empreendedores, investidores, startups e colaboradores do grupo FCJ e suas Venture Builders. Após a divulgação inicial, serão abertas as inscrições a demais interessados. O programa é destinado a qualquer profissional, executivo, empreendedor ou startup interessados em gestão, empreendedorismo e inovação. O conteúdo online serve para colocar em mesmo nível de conhecimento todos os participantes, independentes de sua experiência e formação profissional, com mais de 60h de conteúdo básico e avançado, com grandes referências no setor.'
//     },
//     {
//         question:'Existe desconto em algum hotel?',
//         answer: 'O programa possui parceria institucional com alguns hotéis na cidade de Boston, que com desconto possuem quartos com valor de US$350 a US$450 a diária, a depender da disponibilidade do hotel e temporada. Os quartos podem ser divididos por até 4 pessoas, com o valor da diária também dividido de acordo com o número de hóspedes em cada quarto. Os hotéis parceiros são o The Inn at Longwood Medical Area, e o Hilton Garden Inn. A contratação dos hotéis é de responsabilidade do participante e pode ser orientada pela organização do programa.'
//     },
//     {
//         question:'Preciso ter experiência prévia em gestão?',
//         answer: 'Não é preciso ter experiência prévia ou conhecimento vasto em gestão. O programa online é criado para dar base e aprofundar conhecimentos sobre gestão, empreendedorismo e inovação, para que os participantes, independente da formação profissional e experiência, tenham todos uma vasta experiência.'
//     },
//     {
//         question:'O programa emite certificado? Quem emite o certificado?',
//         answer: 'O programa emite dois certificados de 60h, sendo um certificado referente à parte online do programa, e um certificado para a imersão, ambos emitidos pela Academy Abroad Serviços de Educação LTDA. A Academy Abroad é uma edtech, de caráter privado, sem vínculo direto com o setor educacional de Harvard e MIT, apesar de todo o programa apresentar profissionais das instituições e visitar as localidades de ambas as Universidades. Portanto, o programa não emite certificado em nome de Harvard e MIT.'
//     }
// ]

const ImersoesFaq = () => {
    const { slug } = useParams();
    const API_URL = 'https://api.academyabroad.com.br/api/site/'
    const [data, setData] = useState()

    useEffect(() => {
        axios.get(`${API_URL}imersoes/${slug}`).then(
            response => {
            console.log(response)
            setData(response.data)
            }
        ).catch((error) => console.log(error))
    },[])
    
    const galery = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10,img11, img12, img13, img14, img15, img16, img17, img18, img19]

    const faqData = [
        {
            "pergunta": "Como funciona o programa?",
            "resposta": "O Boston Healthcare Innovation Program (BHI) é um programa híbrido, com 2 meses online contendo videoaulas de especialistas e materiais escritos sobre gestão, empreendedorismo e inovação em saúde. Após essa etapa, o programa presencial ocorre entre 23 e 27 de junho de 2025 em Boston, EUA, incluindo visitas às Universidades de Harvard, MIT e Babson College, com mais de 60h de conteúdo online básico e avançado."
        },
        {
            "pergunta": "Como acontecerá o programa presencial?",
            "resposta": "A etapa presencial, chamada Boston Healthcare Innovation Immersion, será realizada de 23 a 27 de junho de 2025 em Boston. Inclui palestras de professores de Harvard e MIT, workshops práticos, conversas com fundadores de startups, visitas técnicas e atividades opcionais à noite, como jantares e networking."
        },
        {
            "pergunta": "O que está incluído no programa?",
            "resposta": "Inclui o programa online de 2 meses, a imersão presencial em Boston, café da manhã e almoço nos dias presenciais, um welcome kit personalizado e acesso a uma comunidade life long learning. O investimento é de R$ 26.892,00, parceláveis em 12x de R$ 2.241,00 no cartão."
        },
        {
            "pergunta": "Quais as formas de pagamento?",
            "resposta": "O valor pode ser pago à vista via boleto ou PIX, ou parcelado em até 12x de R$ 2.241,00 no cartão de crédito, sem juros."
        },
        {
            "pergunta": "Alimentação está incluída no programa?",
            "resposta": "Café da manhã e almoço estão incluídos nos dias presenciais. Jantar, café da tarde e outros lanches são de responsabilidade do participante."
        },
        {
            "pergunta": "O BHI é totalmente em inglês? Se meu inglês não for fluente, posso participar?",
            "resposta": "A parte online é em português, e a presencial inclui atividades em inglês, com algumas palestras ministradas por professores brasileiros. O inglês utilizado é de fácil compreensão, adequado para estrangeiros."
        },
        {
            "pergunta": "Preciso ser médico(a) para participar?",
            "resposta": "Não, o programa é aberto a profissionais de saúde, executivos, empreendedores e interessados em gestão, empreendedorismo e inovação em saúde, independente da experiência prévia."
        },
        {
            "pergunta": "Existe desconto em algum hotel?",
            "resposta": "Sim, há parcerias com hotéis como The Inn at Longwood Medical Area e Hilton Garden Inn, com diárias entre US$ 350 e US$ 450, que podem ser divididas entre até 4 pessoas. A contratação é responsabilidade do participante."
        },
        {
            "pergunta": "Preciso ter experiência prévia em gestão?",
            "resposta": "Não, o programa oferece base e aprofundamento em gestão, empreendedorismo e inovação, para nivelar o conhecimento entre os participantes, independente da formação ou experiência."
        },
        {
            "pergunta": "O programa emite certificado? Quem emite o certificado?",
            "resposta": "Sim, o programa emite dois certificados de 60h: um do Boston Healthcare Innovation Executive Program (online) e outro do Boston Healthcare Innovation Immersion (presencial), ambos pela Academy Abroad."
        },
        {
            "pergunta": "O que eu preciso ter para ir ao BHI?",
            "resposta": "É necessário um visto americano válido e seguro-viagem. A apólice do seguro deve ser apresentada antes da viagem, sendo de responsabilidade do participante."
        },
        {
            "pergunta": "Ainda não tenho visto, como posso proceder?",
            "resposta": "A Academy Abroad possui parcerias com despachantes que auxiliam na obtenção de visto e passaporte. Após a inscrição, os coordenadores fornecem contatos de parceiros."
        },
        {
            "pergunta": "Orientações importantes:",
            "resposta": "É recomendado chegar em Boston com 2-3 dias de antecedência para evitar problemas com atrasos ou bagagens. No domingo antes do BHI, há um jantar opcional com professores do MIT e participantes para networking."
        }
    ]

    return(
        <>
            {data && (
                <>
                <SEO title={"Imersões FAQ"}/>
                <HeaderTwo styles=" header-style-2" />
                <BuyIn slug={slug} />
                <QuestionsExperience questions={faqData} program={data.titulo}/>
                <GalleryFaq data={galery}/>
                <FooterOne /> 
                </>
            )}
        </>
    )
}
export default ImersoesFaq;